import React from 'react';
import { useIntl } from 'react-intl';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AuthWrapper, RequireAuth } from '@realcity/web-frame/lib/components/Auth';
import { BrowserRouter } from 'react-router-dom';
import GraphqlWrapper from './providers/GraphqlWrapper';
import ApiClientWrapper from './providers/ApiClientWrapper';

const authOptions = {};

const AppContexts: React.FC = ({ children }) => {
    const intl = useIntl();

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={intl.locale}>
            <AuthWrapper options={authOptions}>
                <RequireAuth>
                    <BrowserRouter>
                        <GraphqlWrapper>
                            <ApiClientWrapper>
                                {children}
                            </ApiClientWrapper>
                        </GraphqlWrapper>
                    </BrowserRouter>
                </RequireAuth>
            </AuthWrapper>
        </LocalizationProvider>
    );
};

export default AppContexts;
