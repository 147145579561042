import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import { useController, useFormContext } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import type { DateTime } from 'luxon';
import { Interval } from 'luxon';
import DurationUnitFormat from 'intl-unofficial-duration-unit-format';
import type { AppAlertFormValue } from './AppAlertFormValue';

const ZERO_SECOND = { second: 0, millisecond: 0 };

const PeriodInput: React.FC = () => {
    const intl = useIntl();

    const { control, formState: { errors } } = useFormContext<AppAlertFormValue>();
    const { field: startTimeField } = useController<AppAlertFormValue, 'startTime'>({
        name: 'startTime',
        rules: { required: true, validate: value => value.isValid },
        control,
    });

    const { field: endTimeField } = useController<AppAlertFormValue, 'endTime'>({
        name: 'endTime',
        // eslint-disable-next-line @typescript-eslint/no-extra-parens
        rules: { validate: value => !value || (value.isValid && value > startTimeField.value) },
        control,
    });

    const formattedDurationText = formatDuration(intl.locale, startTimeField.value, endTimeField.value)
        || intl.formatMessage({ id: 'app-alert.until-further-notice' });

    return (
        <>
            <Typography variant="h2">
                <FormattedMessage id="app-alert.period" />
            </Typography>
            <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item lg={5} xs={12}>
                    <DateTimePicker
                        label={<FormattedMessage id="app-alert.start-time" />}
                        {...startTimeField}
                        onClose={startTimeField.onBlur}
                        slotProps={{
                            textField: {
                                required: true,
                                fullWidth: true,
                                onBlur: startTimeField.onBlur,
                                error: Boolean(errors.startTime),
                            },
                        }}
                    />
                </Grid>
                <Grid item lg={5} xs={12}>
                    <DateTimePicker
                        label={<FormattedMessage id="app-alert.end-time" />}
                        {...endTimeField}
                        minDateTime={startTimeField.value}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                            },
                        }}
                    />
                </Grid>
                <Grid item lg={2} xs={12}>
                    <TextField
                        value={formattedDurationText}
                        label={<FormattedMessage id="app-alert.interval" />}
                        disabled
                        fullWidth
                    />
                </Grid>
            </Grid>
        </>
    );
};

function formatDuration(locale: string, startTime: DateTime | null, endTime: DateTime | null): string | null {
    if (!startTime || !startTime.isValid || !endTime || !endTime.isValid) {
        return null;
    }

    const startMinutes = startTime.set(ZERO_SECOND);
    const endMinutes = endTime.set(ZERO_SECOND);
    const timeDifferenceSeconds = Interval.fromDateTimes(startMinutes, endMinutes).length('seconds');

    const durationFormatter = new DurationUnitFormat(locale, { format: '{days} {hours} {minutes}' });
    return durationFormatter.format(timeDifferenceSeconds);
}

export default PeriodInput;
