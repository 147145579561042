import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { parseAppAndVersions } from './alert-utils';
import type { AppAlertFormValue } from './AppAlertFormValue';
import AppVersionsSelector from './AppVersionsSelector';
import { affectedVersionsToString } from '../../utils';
import { API_KEY_PICKER_ENABLED } from '../../config';
import useHasRole from '../../hooks/useHasRole';
import Role from '../../Role';
import type { GroupedApiKeys } from '../../alert-utils';
import { groupApiKeysIfHas, ungroupApiKeys } from '../../alert-utils';

const AppVersionsInputPanel: React.FC = () => {
    const hasRole = useHasRole();
    const { control, getValues, setValue } = useFormContext<AppAlertFormValue>();
    const { field: forceUpdateField } = useController<AppAlertFormValue, 'forceUpdate'>({
        name: 'forceUpdate',
        control,
    });
    const [groupedApiKeys, setGroupedApiKeys] = useState<GroupedApiKeys | null>(
        () => groupApiKeysIfHas(parseAppAndVersions(getValues('appVersions'))),
    );

    const handleGroupedApiKeysChange = (changedApiKeys: GroupedApiKeys) => {
        setGroupedApiKeys(changedApiKeys);
        setValue('appVersions', affectedVersionsToString(ungroupApiKeys(changedApiKeys)));
    };

    const enableDualVersionSelector = hasRole(Role.DEBUG);

    return (
        <>
            <Typography variant="h2">
                <FormattedMessage id="app-alert.version-handling" />
            </Typography>
            <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item sx={{ flex: 1 }}>
                    <Controller
                        control={control}
                        name="appVersions"
                        rules={{ validate: validateAppAndVersions }}
                        render={({ field, fieldState }) => (
                            <>
                                {(!API_KEY_PICKER_ENABLED || enableDualVersionSelector) && (
                                    <TextField
                                        fullWidth
                                        label={<FormattedMessage id="app-alert.affected-versions" />}
                                        error={Boolean(fieldState.error)}
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setGroupedApiKeys(groupApiKeysIfHas(parseAppAndVersions(e.target.value)));
                                        }}
                                    />
                                )}
                                {(API_KEY_PICKER_ENABLED || enableDualVersionSelector) && groupedApiKeys && (
                                    <AppVersionsSelector
                                        value={groupedApiKeys}
                                        onChange={handleGroupedApiKeysChange}
                                    />
                                )}
                                {field.value === '' && (
                                    <Typography sx={{ mt: 1 }}>
                                        <FormattedMessage id="app-alert.no-filter-message" />
                                    </Typography>
                                )}
                            </>
                        )}
                    />
                </Grid>

                <Grid item xs={12} lg={2}>
                    <FormControlLabel
                        control={(
                            <Switch
                                checked={forceUpdateField.value}
                                {...forceUpdateField}
                                onChange={(event, value) => {
                                    forceUpdateField.onChange(value);
                                }}
                            />
                        )}
                        label={<FormattedMessage id="app-alert.force-update" />}
                    />
                </Grid>
            </Grid>
        </>

    );
};

function validateAppAndVersions(input: string): boolean {
    return parseAppAndVersions(input) !== null;
}

export default AppVersionsInputPanel;
