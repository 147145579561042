import React, { useMemo } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTime } from 'luxon';

interface Props {
    seconds: number;
    onChange: (seconds: number) => void;
    disabled?: boolean;
}

const SecondsTimePicker = ({ seconds, onChange, disabled }: Props) => {
    const handleChange = (date: DateTime | null) => {
        if (date === null) {
            return;
        }
        const fixedDate = date.setZone('UTC', { keepLocalTime: true }).set({ year: 1970, month: 1, day: 1 });
        onChange(fixedDate.toSeconds());
    };

    const value = useMemo(() => DateTime.fromSeconds(seconds, { zone: 'UTC' }), [seconds]);

    return (
        <TimePicker
            value={value}
            onChange={handleChange}
            disabled={disabled}
            slotProps={{
                textField: {
                    variant: 'standard',
                    sx: { width: 88, pr: 1 },
                },
            }}
        />
    );
};

export default SecondsTimePicker;
